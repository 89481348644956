import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import Card from "@mui/material/Card";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const data = [
  { id: 0, value: 10, label: "Active" },
  { id: 1, value: 15, label: "Hold" },
  { id: 2, value: 20, label: "Closed" },
];

export default function PieActiveArc() {
  return (
    <Card variant="outlined" sx={{ maxWidth: 550,marginLeft:"50px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between",background:"#d7d8db" }}>
        <Typography fontWeight="bold" margin="8px">
          ANALYTICS AUDIENCE MATRICS
        </Typography>
        <Typography gap="10px" margin="8px">
            <MoreHorizIcon/>
        </Typography>
      </Box>
      <Divider />
      <PieChart
        series={[
          {
            data,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          },
        ]}
        width={500}
        height={300}
      />
    </Card>
  );
}
