import React from "react";
import Card from "../Card";
import { Toolbar, Box } from "@mui/material";
import BarGraph from "../Graphs/BarGraph";
import PieActiveArc from "../Graphs/PieGraph";
import SideNav from "../SideNav";

const Dashboard = () => {
  return (
    <>
      <SideNav>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Card />
          <Toolbar />
          <Box display="flex" gap="20px">
            <Box>
              <BarGraph />
            </Box>
            <Box>
              <PieActiveArc />
            </Box>
          </Box>
        </Box>
      </SideNav>
    </>
  );
};

export default Dashboard;
