import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function Card() {
  return (
    <div style={{ display: "flex" }}>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 375,
          flexGrow: 1,
          color: "#fff",
          background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
          borderRadius: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Visitors
                </Typography>
                <Typography fontSize={30}>13.7K</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: "pointer" }} variant="body2">
                  172,458 Target Users
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                10%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 375,
          flexGrow: 1,
          color: "#fff",
          background:
            "linear-gradient(51deg, rgba(4,73,12,1) 7%, rgba(8,175,27,1) 55%, rgba(147,240,4,1) 95%)",
          borderRadius: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  New Contacts
                </Typography>
                <Typography fontSize={30}>75</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: "pointer" }} variant="body2">
                  172,458 Target Users
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                -5%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 375,
          flexGrow: 1,
          color: "#fff",
          background:
            "linear-gradient(51deg, rgba(227,187,21,1) 2%, rgba(235,115,29,1) 49%,rgba(215,76,12,1) 75%)",
          borderRadius: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Bounce Rate
                </Typography>
                <Typography fontSize={30}>41.6%</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: "pointer" }} variant="body2">
                  472,458 Targeted Target Users
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                17%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 350,
          flexGrow: 1,
          color: "#fff",
          background:
            "linear-gradient(51deg, rgba(227,42,86,1) 16%, rgba(184,29,235,1) 45%, rgba(159,6,246,1) 95%)",
          borderRadius:3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Users
                </Typography>
                <Typography fontSize={30}>19M</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: "pointer" }} variant="body2">
                  172,458 Target Users
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                10%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper> */}
    </div>
  );
}

export default Card;
