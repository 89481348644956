import React from "react";
import SideNav from "../SideNav";

const Authentication = () => {
  return (
    <>
      <SideNav>Authentication</SideNav>
    </>
  );
};

export default Authentication;
