import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Avatar from "@mui/material/Avatar";
// import { useNavigate } from "react-router";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AppsIcon from "@mui/icons-material/Apps";
import SecurityIcon from "@mui/icons-material/Security";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import Marvel from "./Assests/marvel.jpeg";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const settings = ["Logout"];

function SideNav({ children }) {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate2 = useNavigate(); // Move navigate declaration here

  const clearData = () => {
    localStorage.clear();
    navigate2("/");
  };

  useEffect(() => {
    // Fetch data from localStorage
    const data = JSON.parse(localStorage.getItem("user"));

    // Check if emailInput is falsy
    if (!data || !data.emailInput) {
      // Redirect to the home page
      navigate2("/");
    }
  }, [navigate2]); // Add navigate to the dependency array

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#1c2f37",
            color: "#fff",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            flexGrow: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px",
            // marginLeft:12,
          }}
        >
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar style={{ textAlign: "center" }} src={Marvel} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center" onClick={clearData}>
                  {setting}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* <Toolbar /> */}
        <Divider />
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/")}
          >
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon sx={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/application")}
          >
            <ListItemButton>
              <ListItemIcon>
                <AppsIcon sx={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary="Application" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/authentication")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Badge badgeContent={4} color="error">
                  <SecurityIcon sx={{ color: "#fff" }} />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Authentication" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/page")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Badge badgeContent={7} color="error">
                  <AutoStoriesIcon sx={{ color: "#fff" }} />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Page" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          {[
            "Components",
            "Content",
            "Utilities",
            "Forms",
            "Tables",
            "Charts",
            "Maps",
          ].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? (
                    <InboxIcon sx={{ color: "#fff" }} />
                  ) : (
                    <MailIcon sx={{ color: "#fff" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      {children}
      {/* <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Card />
        <Toolbar />
        <Box display="flex" gap="20px">
          <Box>
            <BarGraph />
          </Box>
          <Box>
            <PieGraph />
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
}

export default SideNav;
