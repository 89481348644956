import React, { useState } from "react";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const Login = () => {
  // const [checked, setChecked] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const [emailInput, setEmailInput] = useState();
  const [passwordInput, setPasswordInput] = useState();
  const [rememberMe, setRememberMe] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [formValid, setFormValid] = useState();
  const [success, setSuccess] = useState();
  const navigate = useNavigate();

  const handleEmail = () => {
    if (!isEmail(emailInput)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const handlePassword = () => {
    if (
      !passwordInput ||
      passwordInput.length < 5 ||
      passwordInput.length > 20
    ) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
  };

  const handleSubmit = (e) => {
    let data = { emailInput, passwordInput };
    data = JSON.stringify(data);
    localStorage.setItem("user", data);
    const auth = JSON.parse(localStorage.getItem("user"));
    const signup = JSON.parse(sessionStorage.getItem("user"));
    if (
      auth &&
      signup &&
      auth.emailInput === signup.emailInput &&
      auth.passwordInput === signup.passwordInput
    ) {
      console.log(data);
      setFormValid(null);
      setSuccess("Form Submitted Successfully");
      navigate("/dashboard");
    } else {
      localStorage.clear()
      setFormValid(
        "Invalid credentials. Please check your email and password."
      );
    }

    e.preventDefault();

    setSuccess(null);
    if (emailError || !emailInput) {
      setFormValid("Email is inValid. Please Re-Enter");
      return;
    }

    if (passwordError || !passwordInput) {
      setFormValid("Password is set to 5 - 20 characters. Please Re-Enter");
      return;
    }

    setFormValid(null);
    setSuccess("Form Submitted Successfully");
    navigate("/dashboard");

    console.log(emailInput);
    console.log(passwordInput);
    console.log(rememberMe);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // console.log(rememberMe);

  return (
    <div>
      <p>
        <TextField
          id="standard-basic"
          error={emailError}
          label="Email"
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
          onBlur={handleEmail}
          variant="standard"
          fullWidth
          size="small"
        />
      </p>
      <p>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <InputLabel
            error={passwordError}
            htmlFor="standard-adornment-password"
          >
            Password
          </InputLabel>
          <Input
            fullWidth
            error={passwordError}
            id="standard-adornment-password"
            value={passwordInput}
            onBlur={handlePassword}
            onChange={(event) => setPasswordInput(event.target.value)}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </p>
      <div align="left">
        <Checkbox
          onChange={(event) => setRememberMe(event.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        Remember Me
      </div>
      <p>
        <Button
          onClick={handleSubmit}
          variant="contained"
          startIcon={<LoginIcon />}
          fullWidth
        >
          LOGIN
        </Button>
      </p>
      <p>{formValid && <Alert severity="error">{formValid}</Alert>}</p>
      <p>{success && <Alert severity="success">{success}</Alert>}</p>
    </div>
  );
};

export default Login;
