import React from 'react'
import SideNav from '../SideNav'
import { Typography } from '@mui/material'

const Application = () => {
  return (
    <>
    <SideNav>
      <Typography >
        Applications
      </Typography>
      </SideNav>
    </>
  )
}

export default Application