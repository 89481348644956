import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import Card from "@mui/material/Card";
import { Divider, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Box from "@mui/material/Box";
import BasicTable from "./Table";

function BarGraph() {
  return (
    <Card variant="outlined" sx={{ maxWidth: 700,marginLeft:"10px" }}>
      <Box sx={{ display: "flex" ,justifyContent:"space-between",background:"#d7d8db"}}>
        <Typography fontWeight="bold" margin="8px">
          COUNTRY STATS
        </Typography>
        <Typography gap="10px" margin="8px">
          <ArrowDownwardIcon />
          <FullscreenIcon />
        </Typography>
      </Box>

      <Divider />
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thrusday",
              "Friday",
              "Saturday",
            ],
          },
        ]}
        series={[
          { data: [4, 3, 5, 1, 4, 2, 6] },
          { data: [7, 6, 3, 5, 6, 5, 3] },
        ]}
        width={700}
        height={400}
      />
      <BasicTable/>
    </Card>
  );
}

export default BarGraph;