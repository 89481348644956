import { BrowserRouter, Route, Routes } from "react-router-dom";
// import SideNav from "./SideNav";
// import Dashboard from "./Pages/Dashboard";
import Application from "./Pages/Application";
import Authentication from "./Pages/Authentication";
import Page from "./Pages/Page";
import Home from "./Home";
import PrivateComponent2 from "./PrivateComponent2";
import PrivateComponent from "./PrivateComponent";
import Dashboard from "./Pages/Dashboard";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateComponent2 />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route element={<PrivateComponent />}>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/application" element={<Application />}></Route>
            <Route path="/authentication" element={<Authentication />}></Route>
            <Route path="/page" element={<Page />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
